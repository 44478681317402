import React from "react";
import Layout from "../layout";
import Headline from "../components/Headline";
import TextBlock from "../components/TextBlock";
import { LocalShipping } from "@material-ui/icons";
import { Container, Row, Col } from "react-bootstrap";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import SEO from "../components/seo";
//import  background  from "../img/prodimg2.jpg"
//import kuva4  from '../img/tyomaarain.png'

export default function TuotantoJaToimitus() {
  // Load images
  // Specify sizing, quality etc. here
  const imgdata = useStaticQuery(graphql`
    {
      bg: file(relativePath: { eq: "prodimg2.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 90)
        }
      }
    }
  `);
  const bgImageData = imgdata.bg;

  return (
    <>
      <SEO title="Tuotanto" description="Tuotanto ja toimitus" />
      <Layout>
        <Headline
          HeadIcon={LocalShipping}
          header="Tuotanto ja toimitus"
          backgroundImageData={bgImageData}
        />
        <Container className="page_container">
          <Row>
            <Col md="6">
              <TextBlock
                Header="Tuotanto"
                Paragraph="Tilauksien siirto tuotantoon ja tuotannon työmääräimet. Automaattinen reaaliaikainen varastonsaldojen päivitys. "
              ></TextBlock>

              <TextBlock
                Header="Tuotantoerät"
                Paragraph="Tuotannon tehostamiseksi toiminto, jolla useita tilauksia valmistetaan kerralla. Järjestelmä laskee automaattisesti tilauksien tuotteet muodostaen näistä tuotannon tarvitsemat kokonaistiedot ja lukumäärät."
              ></TextBlock>
            </Col>
            <Col md="6">
              <StaticImage
                src="../../content/images/tyomaarain.png"
                className="kuva"
                alt="työmääräin"
                layout="fullWidth"
              />
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  );
}
